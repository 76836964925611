import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import "core-js/modules/es7.object.values";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
import { Followupanalysis } from '@/api/user_restPwd/Managesubordinates/department';
import { getStructureListArr } from '@/api/PublicAPI';
export default {
  components: {
    Head: Head,
    nxDataTabs: nxDataTabs
  },
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '跟进分析',
      value1: '',
      bumen: '',
      bumenoptions: [],
      //部门
      //卡片
      easyDataOption: {
        style: 'width:20%',
        span: 4,
        data: [{
          title: '尚未沟通',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/1095ff91752325f2b5760858ab32bed1.jpg',
          key: 'icon-goutong'
        }, {
          title: '一般意向',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/7126855260e360f0d07aeec2367b21b6.jpg',
          key: 'icon-yixiang2'
        }, {
          title: '重点意向',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/4885d217e52f37acd8bfae188346fb30.jpg',
          key: 'icon-yixiang'
        }, {
          title: '长期跟进',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2cbda790813b1017465cccf9a162afc6.jpg',
          key: 'icon-genzong'
        }, {
          title: '收资料',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/4885d217e52f37acd8bfae188346fb30.jpg',
          key: 'icon-ziliao'
        }, {
          title: '缴费',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2cbda790813b1017465cccf9a162afc6.jpg',
          key: 'icon-jiaofei'
        }, {
          title: '其他需求',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2bdf5eccfc829372f28edb8f90b605f4.jpg',
          key: 'icon-qita'
        }, {
          title: '无效的数据',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/de58e9576c4d4f63528e215ef15b8d9b.jpg',
          key: 'icon-wuxiao'
        }, {
          title: '意向未明确',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/7126855260e360f0d07aeec2367b21b6.jpg',
          key: 'icon-24pxbeifen'
        }, {
          title: '长期跟踪',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/1095ff91752325f2b5760858ab32bed1.jpg',
          key: 'icon-genzong'
        }]
      },
      //折线图
      DiscountFigure: {
        columns: ['data_time', '一般意向', '其他需求', '尚未沟通', '意向未明确', '收资料', '无效的数据', '缴费', '重点意向', '长期跟踪', '长期跟进'],
        rows: []
      },
      tableData: [],
      start_time: '',
      end_time: '',
      k_data: []
    };
  },
  created: function created() {
    if (this.course != 1) {
      this.GetList();
      this.getStructure();
    } else {}
  },
  methods: {
    //获取列表
    GetList: function GetList() {
      var _this = this;
      Followupanalysis({
        stime: this.start_time,
        etime: this.end_time
      }).then(function (respomse) {
        _this.tableData = respomse.data.l_data;
        //卡片
        Object.values(respomse.data.k_data).forEach(function (item, index) {
          _this.easyDataOption.data[index].title = respomse.data.k_data[index].name;
          _this.easyDataOption.data[index].count = respomse.data.k_data[index].num;
        });
        //折线图
        _this.DiscountFigure.rows = Object.values(respomse.data.z_data);
      });
    },
    //数据统计范围树状图
    getStructure: function getStructure() {
      var _this2 = this;
      getStructureListArr().then(function (respomse) {
        _this2.bumenoptions = respomse.data;
      });
    },
    //时间
    DataTime: function DataTime() {
      var _this3 = this;
      if (this.value1 !== null) {
        var s_time = this.value1[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.value1[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this3.GetList();
        });
      } else {
        this.$nextTick(function () {
          _this3.GetList();
        });
      }
    },
    //数据统计范围
    handleChange: function handleChange(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
    }
  }
};